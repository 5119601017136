<template>
    <div id="list-ground-water-equipment-component" class="pa-4">
        <empty-page-component
            :link_img="'/images/error/in_progress.png'"
            :main_text="$t('global.soon_available')"
            :secondary_text="$t('global.module_under_construction')"
            :show_secondary_text="true"
        />
    </div>
</template>

<script>
import EmptyPageComponent from '@/components/Error/EmptyPageComponent'

export default {
    name: 'ListGroundWaterEquipmentComponent',
    components: { EmptyPageComponent },
}
</script>
