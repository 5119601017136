<template>
    <svg fill="none" height="30" viewBox="0 0 45 30" width="45" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_1836_57446" fill="white">
            <rect height="6" rx="1" width="27" x="9" />
        </mask>
        <rect class="stroke-svg" height="6" mask="url(#path-1-inside-1_1836_57446)" rx="1" stroke-width="3" width="27" x="9" />
        <mask id="path-2-inside-2_1836_57446" fill="white">
            <rect height="12" rx="1" width="25.92" x="9.54004" y="5" />
        </mask>
        <rect
            class="stroke-svg"
            height="12"
            mask="url(#path-2-inside-2_1836_57446)"
            rx="1"
            stroke-width="3"
            width="25.92"
            x="9.54004"
            y="5"
        />
        <mask id="path-3-inside-3_1836_57446" fill="white">
            <rect height="12" rx="1" width="25.92" x="9.54004" y="15" />
        </mask>
        <rect
            class="stroke-svg"
            height="12"
            mask="url(#path-3-inside-3_1836_57446)"
            rx="1"
            stroke-width="3"
            width="25.92"
            x="9.54004"
            y="15"
        />
        <mask id="path-4-inside-4_1836_57446" fill="white">
            <rect height="4" rx="1" width="6.48" x="12.2402" y="26" />
        </mask>
        <rect
            class="stroke-svg"
            height="4"
            mask="url(#path-4-inside-4_1836_57446)"
            rx="1"
            stroke-width="3"
            width="6.48"
            x="12.2402"
            y="26"
        />
        <mask id="path-5-inside-5_1836_57446" fill="white">
            <rect height="4" rx="1" width="6.48" x="26.2803" y="26" />
        </mask>
        <rect
            class="stroke-svg"
            height="4"
            mask="url(#path-5-inside-5_1836_57446)"
            rx="1"
            stroke-width="3"
            width="6.48"
            x="26.2803"
            y="26"
        />
        <mask id="path-6-inside-6_1836_57446" fill="white">
            <rect height="4" rx="1" width="5.94" x="20.3398" y="19" />
        </mask>
        <rect
            class="stroke-svg"
            height="4"
            mask="url(#path-6-inside-6_1836_57446)"
            rx="1"
            stroke-width="3"
            width="5.94"
            x="20.3398"
            y="19"
        />
        <mask id="path-7-inside-7_1836_57446" fill="white">
            <rect height="4" rx="1" width="5.94" x="20.3398" y="9" />
        </mask>
        <rect
            class="stroke-svg"
            height="4"
            mask="url(#path-7-inside-7_1836_57446)"
            rx="1"
            stroke-width="3"
            width="5.94"
            x="20.3398"
            y="9"
        />
    </svg>
</template>

<script>
export default {
    name: 'HiveSvg',
}
</script>
