<template>
    <div id="information-asset-widget-component">
        <widget-wrapper-component :title="$t('information_asset_widget_component.general_view')">
            <template v-slot:default>
                <div id="information-asset-widget-container" class="information-asset-widget-container">
                    <template v-for="(n, index) in total_models">
                        <div :key="n" class="d-flex flex-column gap-2 position-relative">
                            <template v-if="configuration[index]['is_required']">
                                <div class="d-flex justify-center">
                                    <display-asset-content-level-component
                                        :asset="asset"
                                        :asset_module="asset_module"
                                        :index_model="index"
                                        :scale="0.7"
                                    />
                                </div>

                                <div v-if="configuration[index]['model_name']">
                                    <p class="mb-1 font-12 font-color-medium">
                                        {{ $t('information_asset_widget_component.model_name') }}
                                    </p>

                                    <p class="pl-4 font-16 font-color-normal">
                                        {{ configuration[index]['model_name'] }}
                                    </p>
                                </div>

                                <div>
                                    <p class="mb-1 font-12 font-color-medium">
                                        {{ $t('information_asset_widget_component.content') }}
                                    </p>

                                    <p class="pl-4 font-16 font-color-normal">
                                        {{
                                            $store.getters['asset_module/liquid_by_id_module_and_id_asset_and_model'](
                                                asset_module.id_module,
                                                asset.id_asset,
                                                index
                                            ).name
                                        }}
                                    </p>
                                </div>

                                <div>
                                    <p class="mb-1 font-12 font-color-medium">
                                        {{ $t('information_asset_widget_component.quantity') }}
                                    </p>

                                    <div class="pl-4">
                                        <div class="d-flex gap-1 font-color-normal">
                                            <span
                                                v-if="getConfigurationDatatableAssetVisionData(index, 'value')"
                                                class="font-weight-bold font-16"
                                            >
                                                {{ getConfigurationDatatableAssetVisionData(index, 'value') }}
                                                {{ getConfigurationDatatableAssetVisionData(index, 'unity') }}
                                            </span>

                                            <template
                                                v-if="
                                                    getConfigurationDatatableAssetVisionData(index, 'value') &&
                                                    getConfigurationDatatableAssetVisionData(index, 'total')
                                                "
                                            >
                                                /
                                            </template>

                                            <span v-if="getConfigurationDatatableAssetVisionData(index, 'total')" class="font-14">
                                                {{ getConfigurationDatatableAssetVisionData(index, 'total') }}
                                                {{ getConfigurationDatatableAssetVisionData(index, 'unity') }}
                                            </span>
                                        </div>

                                        <div
                                            v-if="getConfigurationDatatableAssetVisionData(index, 'percent')"
                                            class="font-weight-bold font-12 font-italic font-color-normal"
                                        >
                                            {{ getConfigurationDatatableAssetVisionData(index, 'percent') }} %
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <v-icon class="font-color-light"> mdi-cancel</v-icon>
                            </template>
                        </div>
                    </template>
                </div>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import DisplayAssetContentLevelComponent from '@/components/Equipment/Generic/DisplayAssetContentLevelComponent.vue'
import displayHuman from '@/mixin/displayHuman'

export default {
    name: 'InformationAssetWidgetComponent',
    components: { DisplayAssetContentLevelComponent, WidgetWrapperComponent },
    mixins: [displayHuman],
    props: {
        asset: {
            type: Object,
            required: true,
        },
        asset_module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        total_models() {
            return this.asset_module.total_models || 0
        },
        configuration() {
            return JSON.parse(this.asset.configuration)
        },
    },
    methods: {
        handleWidgetResize() {
            const divWidth = document.getElementById('information-asset-widget-container')?.clientWidth
            const nbAsset = divWidth / 300
            const widgetContainer = document.getElementById('information-asset-widget-container')
            if (widgetContainer) {
                widgetContainer.style.gridTemplateColumns = `repeat(${
                    Math.floor(nbAsset) < this.total_models ? Math.floor(nbAsset) : this.total_models
                }, 1fr)`
            }
        },

        getConfigurationDatatableAssetVisionData(model, data) {
            if (
                !this.asset.configuration_datatable_asset_vision_data ||
                !JSON.parse(this.asset.configuration_datatable_asset_vision_data)[model]
            ) {
                return false
            }

            let config = JSON.parse(this.asset.configuration_datatable_asset_vision_data)[model]

            let result
            switch (data) {
                case 'value': {
                    result = config['value'] ?? config['volume_net']
                    break
                }
                case 'total': {
                    result = config['total'] ?? config['volume_total']
                    break
                }
                case 'percent': {
                    if (config['percent']) {
                        result = config['percent']
                    } else if (config['value'] && config['total']) {
                        result = (config['value'] * 100) / config['total']
                    } else if (config['volume_net'] && config['volume_total']) {
                        result = (config['volume_net'] * 100) / config['volume_total']
                    }
                    break
                }
                case 'unity': {
                    result = config['unity'] ?? 'L'
                    break
                }
                default: {
                    result = config[data] ?? '-'
                }
            }

            return typeof result === 'number' ? this.displayToHumanNumberWithSpace(result) : result
        },
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.handleWidgetResize()
        })
        this.handleWidgetResize()
    },
}
</script>

<style scoped>
.information-asset-widget-container {
    display: grid;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    row-gap: 12px;
    grid-template-columns: repeat(4, 1fr);
}
</style>