<template>
    <div id="equipment-page">
        <toolbar-component v-bind:title="page_name_equipment" />

        <v-tabs
            v-if="asset_types.length + $store.getters['asset_module/asset_modules'].length > 0"
            v-model="tab_selected"
            active-class="primary--text"
            background-color="secondary"
            class="tabs-asset-types default-arrows-tabs"
            show-arrows
            slider-color="primary"
        >
            <template v-if="asset_types.some((asset_type) => asset_type.key === 'cuve')">
                <v-tab
                    :class="tab_selected === 'equipment-tank' ? 'primary--text' : 'primary--text tab-opacity'"
                    data-cy="tab-tank"
                    href="#equipment-tank"
                >
                    <tank-svg />
                    <span class="ml-2 primary--text">{{ $t('equipment_page.tank') }}</span>
                </v-tab>
                <v-tab-item class="background" value="equipment-tank">
                    <list-tank-equipment-component :asset_type="asset_types.filter((asset_type) => asset_type.key === 'cuve')[0]" />
                </v-tab-item>
            </template>

            <template v-if="asset_types.some((asset_type) => asset_type.key === 'silo')">
                <v-tab :class="tab_selected === 'equipment-silo' ? 'primary--text' : 'primary--text tab-opacity'" href="#equipment-silo">
                    <silo-svg />
                    <span class="primary--text">Silos</span>
                </v-tab>
                <v-tab-item class="background" value="equipment-silo">
                    <list-silo-equipment-component />
                </v-tab-item>
            </template>

            <template v-if="asset_types.some((asset_type) => asset_type.key === 'chaudiere')">
                <v-tab
                    :class="tab_selected === 'equipment-boiler' ? 'primary--text' : 'primary--text tab-opacity'"
                    href="#equipment-boiler"
                >
                    <boiler-svg />
                    <span class="primary--text">{{ $t('equipment_page.boiler') }}</span>
                </v-tab>
                <v-tab-item class="background" value="equipment-boiler">
                    <list-boiler-equipment-component />
                </v-tab-item>
            </template>

            <template v-if="asset_types.some((asset_type) => asset_type.key === 'hive')">
                <v-tab :class="tab_selected === 'equipment-hive' ? 'primary--text' : 'primary--text tab-opacity'" href="#equipment-hive">
                    <hive-svg />
                    <span class="primary--text">{{ $t('equipment_page.hive') }}</span>
                </v-tab>
                <v-tab-item class="background" value="equipment-hive">
                    <list-hive-equipment-component />
                </v-tab-item>
            </template>

            <template v-if="asset_types.some((asset_type) => asset_type.key === 'ouvrage')">
                <v-tab
                    :class="tab_selected === 'equipment-ouvrage' ? 'primary--text' : 'primary--text tab-opacity'"
                    href="#equipment-ouvrage"
                >
                    <ouvrage-svg />
                    <span class="ml-2 primary--text">{{ $t('equipment_page.ouvrage') }}</span>
                </v-tab>
                <v-tab-item class="background" value="equipment-ouvrage">
                    <list-ouvrage-equipment-component />
                </v-tab-item>
            </template>

            <template v-if="asset_types.some((asset_type) => asset_type.key === 'enrouleur')">
                <v-tab :class="tab_selected === 'equipment-reel' ? 'primary--text' : 'primary--text tab-opacity'" href="#equipment-reel">
                    <reel-svg />
                    <span class="ml-1 primary--text">{{ $t('equipment_page.reel') }}</span>
                </v-tab>
                <v-tab-item class="background" value="equipment-reel">
                    <list-reel-equipment-component />
                </v-tab-item>
            </template>

            <template v-if="asset_types.some((asset_type) => asset_type.key === 'ground_water')">
                <v-tab
                    :class="tab_selected === 'equipment-ground-water' ? 'primary--text' : 'primary--text tab-opacity'"
                    href="#equipment-ground-water"
                >
                    <ground-water-svg />
                    <span class="ml-2 primary--text">{{ $t('equipment_page.ground_water') }}</span>
                </v-tab>
                <v-tab-item class="background" value="equipment-ground-water">
                    <list-ground-water-equipment-component />
                </v-tab-item>
            </template>

            <template v-if="asset_types.some((asset_type) => asset_type.key === 'generic')">
                <v-tab :class="tab_selected === 'equipment-hive' ? 'primary--text' : 'primary--text tab-opacity'" href="#equipment-hive">
                    <hive-svg />
                    <span class="ml-2 primary--text">{{ $t('equipment_page.hive') }}</span>
                </v-tab>
                <v-tab-item class="background" value="equipment-hive">
                    <list-hive-equipment-component />
                </v-tab-item>
            </template>

            <template
                v-for="module in $store.getters['asset_module/asset_modules'].filter((item) =>
                    userHasAccess(item.designation, role_vision)
                )"
            >
                <v-tab
                    :key="module.id_module"
                    :class="`${tab_selected === module.designation ? 'primary--text' : 'primary--text tab-opacity'}`"
                    :href="`#${module.designation}`"
                >
                    <div class="mr-1 primary--text display-flex-align-center gap-1">
                        <custom-icon-component :icon="module.menu_icon" class="font-26" color="primary" default_icon="mdi-remote" />
                        {{ $t(`modules.${module.designation}`) }}
                    </div>
                </v-tab>

                <v-tab-item :key="module.id_module" :value="module.designation" class="background">
                    <list-asset-component :asset_module="module" />
                </v-tab-item>
            </template>
        </v-tabs>

        <template v-else-if="asset_types.length === 1">
            <list-tank-equipment-component
                v-if="asset_types[0].key === 'cuve'"
                :asset_type="asset_types.filter((asset_type) => asset_type.key === 'cuve')[0]"
            />

            <list-boiler-equipment-component v-if="asset_types[0].key === 'chaudiere'" />

            <list-silo-equipment-component v-if="asset_types[0].key === 'silo'" />

            <list-hive-equipment-component v-if="asset_types[0].key === 'hive'" />

            <list-ouvrage-equipment-component v-if="asset_types[0].key === 'ouvrage'" />

            <list-reel-equipment-component v-if="asset_types[0].key === 'enrouleur'" />

            <list-ground-water-equipment-component v-if="asset_types[0].key === 'ground_water'" />

            <list-hive-equipment-component v-if="asset_types[0].key === 'generic'" />
        </template>
    </div>
</template>

<script>
import ToolbarComponent from '@/components/Global/ToolbarComponent'

import ListBoilerEquipmentComponent from '@/components/Equipment/ListBoilerEquipmentComponent'
import ListGroundWaterEquipmentComponent from '@/components/Equipment/ListGroundWaterEquipmentComponent'
import ListHiveEquipmentComponent from '@/components/Equipment/ListHiveEquipmentComponent'
import ListOuvrageEquipmentComponent from '@/components/Equipment/ListOuvrageEquipmentComponent'
import ListReelEquipmentComponent from '@/components/Equipment/ListReelEquipmentComponent'
import ListSiloEquipmentComponent from '@/components/Equipment/ListSiloEquipmentComponent'
import ListTankEquipmentComponent from '@/components/Equipment/ListTankEquipmentComponent'

import BoilerSvg from '@/components/Svg/BoilerSvg'
import GroundWaterSvg from '@/components/Svg/GroundWaterSvg'
import HiveSvg from '@/components/Svg/HiveSvg'
import OuvrageSvg from '@/components/Svg/OuvrageSvg'
import ReelSvg from '@/components/Svg/ReelSvg'
import SiloSvg from '@/components/Svg/SiloSvg'
import TankSvg from '@/components/Svg/TankSvg'
import ListAssetComponent from '@/components/Equipment/Generic/ListAssetComponent.vue'
import CustomIconComponent from '@/components/Global/CustomIconComponent.vue'

export default {
    name: 'EquipmentPage',
    components: {
        CustomIconComponent,
        ListAssetComponent,
        ToolbarComponent,
        ListTankEquipmentComponent,
        BoilerSvg,
        GroundWaterSvg,
        HiveSvg,
        OuvrageSvg,
        ReelSvg,
        SiloSvg,
        TankSvg,
        ListBoilerEquipmentComponent,
        ListGroundWaterEquipmentComponent,
        ListHiveEquipmentComponent,
        ListOuvrageEquipmentComponent,
        ListReelEquipmentComponent,
        ListSiloEquipmentComponent,
    },
    data() {
        return {
            tab_selected: '',
        }
    },
    computed: {
        asset_types: {
            get() {
                return this.$store.getters['asset_types/asset_types_which_have_asset']
            },
            set() {
                this.getProfessionalAssetTypes()
            },
        },
    },
    mounted() {
        this.$pushy.register()
        this.getNotifications()
    },
}
</script>

<style>
.v-slide-group__prev {
    display: none !important;
}
</style>
