<template>
    <svg fill="none" height="30" viewBox="0 0 45 30" width="45" xmlns="http://www.w3.org/2000/svg">
        <path
            class="background-svg"
            clip-rule="evenodd"
            d="M20 28.5C23.2218 28.5 26.1799 27.3714 28.5007 25.4881C28.5909 25.3143 28.7471 25.1804 28.9361 25.1193C31.735 22.6458 33.5 19.029 33.5 15C33.5 7.54416 27.4558 1.5 20 1.5C12.5442 1.5 6.5 7.54416 6.5 15C6.5 22.4558 12.5442 28.5 20 28.5ZM20 30C23.6196 30 26.9397 28.718 29.5311 26.5832H36.3334C37.8522 26.5832 39.0834 25.352 39.0834 23.8332V12.4167H39.9998C40.4141 12.4167 40.7498 12.0809 40.7498 11.6667C40.7498 11.2524 40.4141 10.9167 39.9998 10.9167H39.0834V8.33319C39.0834 7.91897 38.7477 7.58319 38.3334 7.58319C37.9192 7.58319 37.5834 7.91897 37.5834 8.33319V10.9167H36.6665C36.2523 10.9167 35.9165 11.2524 35.9165 11.6667C35.9165 12.0809 36.2523 12.4167 36.6665 12.4167H37.5834V23.8332C37.5834 24.5235 37.0238 25.0832 36.3334 25.0832H31.1055C33.5253 22.4197 35 18.882 35 15C35 6.71573 28.2843 0 20 0C11.7157 0 5 6.71573 5 15C5 23.2843 11.7157 30 20 30ZM20 23.5C24.6945 23.5 28.5 19.6945 28.5 15C28.5 10.3056 24.6945 6.50005 20 6.50005C15.3056 6.50005 11.5 10.3056 11.5 15C11.5 19.6945 15.3056 23.5 20 23.5ZM20 25C25.5229 25 30 20.5229 30 15C30 9.4772 25.5229 5.00005 20 5.00005C14.4772 5.00005 10 9.4772 10 15C10 20.5229 14.4772 25 20 25ZM23.4998 14.9998C23.4998 16.9328 21.9328 18.4998 19.9998 18.4998C18.0668 18.4998 16.4998 16.9328 16.4998 14.9998C16.4998 13.0668 18.0668 11.4998 19.9998 11.4998C21.9328 11.4998 23.4998 13.0668 23.4998 14.9998ZM24.9998 14.9998C24.9998 17.7612 22.7612 19.9998 19.9998 19.9998C17.2383 19.9998 14.9998 17.7612 14.9998 14.9998C14.9998 12.2383 17.2383 9.99975 19.9998 9.99975C22.7612 9.99975 24.9998 12.2383 24.9998 14.9998Z"
            fill="white"
            fill-rule="evenodd"
        />
    </svg>
</template>

<script>
export default {
    name: 'ReelSvg',
}
</script>
